import { NavigationGuard, RouteLocationNormalized } from 'vue-router'
import { getStaticUserData } from '@/api/utils/user'
import { useFetchCompanyWithDescendants } from './featuresAccessGuard'

export const patchMissingCompanyId: NavigationGuard = (to, from) => {
  const { companyId } = getStaticUserData()

  if (companyId && !to.params.companyId) {
    const expectedCompanyId = from.name
      ? Number(from.params.companyId)
      : companyId

    return replaceCompanyId(to, expectedCompanyId)
  }
}

export const companySwitchRedirect: NavigationGuard = (to, from) => {
  if (
    from.name &&
    to.meta.companySwitchRedirect &&
    to.params.companyId !== from.params.companyId
  ) {
    return {
      ...to.meta.companySwitchRedirect,
      params: {
        ...to.meta.companySwitchRedirect.params,
        companyId: to.params.companyId,
      },
    }
  }
}

export const companyAccessGuard: NavigationGuard = async (to) => {
  const { companyId } = getStaticUserData()

  if (companyId && !(await hasAccessToCompany(to))) {
    return replaceCompanyId(to, companyId)
  }
}

const hasAccessToCompany = async (
  to: RouteLocationNormalized
): Promise<boolean> => {
  const { getCompaniesCacheFirst } = useFetchCompanyWithDescendants()
  const companies = await getCompaniesCacheFirst()
  const companyId = Number(to.params.companyId)

  return companies?.some((company) => company.id === companyId) ?? false
}

const replaceCompanyId = (
  to: RouteLocationNormalized,
  companyId: number
): RouteLocationNormalized | undefined => {
  return {
    ...to,
    params: {
      ...to.params,
      companyId: companyId.toString(),
    },
  }
}
